@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');


body {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
  background-image: url('./Img/astrobg.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
label,
div,
p {
  font-family: "Plus Jakarta Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0075FF;
  /* opacity: 40%; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

:focus-visible {
  outline: unset !important;
}

.head-text-sidebar button {
  color: #fff;
  text-transform: capitalize;
}

::placeholder{
  color: #ccc;
  opacity: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}